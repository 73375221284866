// extracted by mini-css-extract-plugin
export var Active = "Success-module--Active--028e2";
export var Advice = "Success-module--Advice--a710c";
export var AdviceBlock = "Success-module--AdviceBlock--b1b37";
export var Button = "Success-module--Button--38e63";
export var Checkbox = "Success-module--Checkbox--20a96";
export var Empty = "Success-module--Empty--c8730";
export var Error = "Success-module--Error--6e304";
export var Half = "Success-module--Half--9aa49";
export var Highlight = "Success-module--Highlight--040e0";
export var Input = "Success-module--Input--71077";
export var Loading = "Success-module--Loading--ca97f";
export var LoadingWheel = "Success-module--LoadingWheel--a8ccc";
export var Message = "Success-module--Message--14caf";
export var Pay = "Success-module--Pay--c8466";
export var PayBlock = "Success-module--PayBlock--b0c0d";
export var Prices = "Success-module--Prices--83c28";
export var PricesBlock = "Success-module--PricesBlock--f7417";
export var Selected = "Success-module--Selected--909d9";
export var Silver = "Success-module--Silver--ba36f";
export var Subscribe = "Success-module--Subscribe--cd2c4";
export var SubscribeInner = "Success-module--SubscribeInner--547f7";
export var SubscribeMain = "Success-module--SubscribeMain--61e72";
export var SubscribeSide = "Success-module--SubscribeSide--7698c";
export var Tabs = "Success-module--Tabs--cb67b";
export var Title = "Success-module--Title--81365";
export var User = "Success-module--User--10118";
import React from "react"
import Layout from "../components/Layout/layout"
import Success from "../Views/Website/Success/Success";

const SubscribePage = (props: any) => {
  const search = props.location.search ? props.location.search.replace("?", "").split("&") : "";
  const PackageID = search ? search.find(p => p.split("=")[0] === "PackageID").split("=")[1] : "";
  const PackageLength = search ? search.find(p => p.split("=")[0] === "PackageLength").split("=")[1] : "";
  const AdviceID = search ? search.find(p => p.split("=")[0] === "AdviceID").split("=")[1] : "";
  const AdviceLength = search ? search.find(p => p.split("=")[0] === "AdviceLength").split("=")[1] : "";
  const RedirectFlowID = search ? search.find(p => p.split("=")[0] === "redirect_flow_id").split("=")[1] : "";

  return (
    <Layout>
      <Success PackageID={PackageID} Length={PackageLength} AdviceLine={AdviceID} AdviceLineLength={AdviceLength} RedirectFlowID={RedirectFlowID} />
    </Layout>
  );
};

export default SubscribePage;
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../../components/Banner/Banner';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import { Loading } from '../../../components/Loading/Loading';
import { UserContext } from '../../../Context/UserContext';
import { API_GCAddCustomer } from '../../../Services/ApiRoutes';
import { Fetch } from '../../../Services/Fetch';
import * as styles from './Success.module.scss';

interface Props {
  PackageID: string;
  Length: string;
  AdviceLine: string;
  AdviceLineLength: string;
  RedirectFlowID: string;
}

type SubPackage = {
  PackageID: number;
  PackageLength: number;
  AdviceID: number;
  AdviceLength: number;
  RedirectFlowID: string | undefined;
}

const Success: React.FC<Props> = ({ PackageID, Length, AdviceLine, AdviceLineLength, RedirectFlowID }) => {
  const [message, setMessage] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { User } = useContext(UserContext);

  useEffect(() => {
    if (User.ID)
      RedirectFlow();
  }, [User]);

  const RedirectFlow = () => {
    setLoading(true);
    const data: SubPackage = {
      PackageID: parseInt(PackageID),
      PackageLength: parseInt(Length),
      AdviceID: parseInt(AdviceLine),
      AdviceLength: parseInt(AdviceLineLength),
      RedirectFlowID: RedirectFlowID
    }

    Fetch(`${API_GCAddCustomer}`, data)
      .then((Response) => {
        setMessage(Response.Message);
        setSuccess(Response.Success);
      }).catch((e) => {
        setMessage(e.Message);
      })
    setLoading(false);
  }

  return (<>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title>PrimeHR :: Confirming Successful Subscription</title><meta charSet='utf-8' />
    </Helmet>

    <Banner />

    <Breadcrumb Trail={[
      { To: `/Subscribe?PackageID=${PackageID}&PackageLength=${Length}&AdviceID=${AdviceLine}&AdviceLength=${AdviceLineLength}`, Text: "Subscribe" },
      { To: "", Text: "Success" }
    ]} />

    {loading ? <Loading /> : !success ? <div className={styles.Message}>
      <h1>{message}</h1>
      <i>
        <FontAwesomeIcon icon={faTimesCircle} />
      </i>
    </div> : <div className={styles.Message}>
      <h1>You Have Successfully Subscribed!</h1>
      <i>
        <FontAwesomeIcon icon={faCheckCircle} />
      </i>
      <Link to={"/Portal"} className={styles.Button}>
        Start Using Now
      </Link>
    </div>}
  </>
  );
};

export default Success;
